<template>
    <div>
      <div class="ftb_details_header_mobile">
        <div v-if="match">
            <div class="ftb_details_header_section">
                <div class="ftb_details_header_content">
                    <div class="ftb_details_header_results"
                        v-if="(match.halfTime && match.fullTime) && (match.status == 'Finished' || match.status == 'Finished AET' || match.status == 'Finished AP')">
                        <ul>
                            <li v-if="match.halfTime">
                                <span>
                                  {{$t('football.match-detail.first half score')}} : {{match.halfTime}}
                                </span>
                            </li>
                            <li v-if="match.fullTime && (match.status == 'Finished' || match.status == 'Finished AET' || match.status == 'Finished AP')">
                                <span>
                                  {{$t('football.match-detail.second half score')}} : {{calculateFinalScore(match.fullTime,match.halfTime)}}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="ftb_details_header_name_pic_status">
                        <div class="ftb_details_header_name_pic">
                            <strong>
                                <img :alt="match.competitors[0].isHome==true?match.competitors[0].name:match.competitors[1].name"
                                    :src="$storageURL+'images/football/'+(match.competitors[0].isHome==true?match.competitors[0].id:match.competitors[1].id)+'_100x100.webp'"
                                    :onerror="$teamDefaultLogo()">
                            </strong>
                            <h3>
                                {{match.competitors[0].isHome==true?match.competitors[0].name:match.competitors[1].name}}
                            </h3>
                        </div>
                        <div class="ftb_details_header_status">
                            <h5><a href="javascript:;" >{{match.score}}</a></h5>
                            <!-- <label>{{match.shortStatus}}</label> -->
                            <label
                                v-if="match.minutesCal"
                                style="width: auto !important"
                                >{{ match.minutesCal }}</label
                            >
                            <label
                                v-else
                                style="width: auto !important"
                                >{{ match.shortStatus }}</label
                            >
                        </div>
                        <div class="ftb_details_header_name_pic ftb_dtl_away_team">
                            <h3 class="ftb_details_header_name_pic_away">
                                {{match.competitors[0].isHome==false?match.competitors[0].name:match.competitors[1].name}}
                            </h3>
                                <strong>
                                <img :alt="match.competitors[0].isHome==false?match.competitors[0].name:match.competitors[1].name"
                                    :src="$storageURL+'images/football/'+(match.competitors[0].isHome==false?match.competitors[0].id:match.competitors[1].id)+'_100x100.webp'"
                                    :onerror="$teamDefaultLogo()">
                            </strong>
                        </div>
                    </div>
                    <div class="players_name_status_outer">
                            <ul class="players_name_status">
                                <li v-for="event in match.competitors[0].isHome==true?match.competitors[0].events.slice(0,5):match.competitors[1].events.slice(0,5)" :key="event.minutes">
                                    <label v-if="event.type">
                                        {{event.player_name}}
                                        <b>{{event.minutes}}' </b>
                                        <img  :src="require('@/assets/images/'+event.type+'.webp')" alt="player name">
                                    </label>
                                    <label v-if="!event.type">
                                        {{event.player_in_name}}
                                        <img :src="require('@/assets/images/p_in.webp')" alt="player in name">
                                        <b>{{event.minutes}}'</b>
                                    </label>
                                    <label v-if="!event.type">
                                        {{event.player_out_name}}
                                        <b>{{event.minutes}}'</b>
                                        <img :src="require('@/assets/images/p_out.webp')" alt="player out name">
                                    </label>
                                </li>
                            </ul>
                            <ul class="players_name_status">
                                <li v-for="event in match.competitors[0].isHome==false?match.competitors[0].events.slice(0,5):match.competitors[1].events.slice(0,5)" :key="event.minutes">
                                    <!-- v-for="let goal of away_goals"-->
                                    <label v-if="event.type">
                                        <img  :src="require('@/assets/images/'+event.type+'.webp')" alt="player name">
                                        <b>{{event.minutes}}' </b>
                                        {{event.player_name}}
                                    </label>
                                    <label v-if="!event.type">
                                        <img :src="require('@/assets/images/p_in.webp')" alt="player in name"><b>{{event.minutes}}'</b>
                                        {{event.player_in_name}}
                                    </label>
                                    <label v-if="!event.type">
                                        <img :src="require('@/assets/images/p_out.webp')" alt="player out name"><b>{{event.minutes}}'</b>
                                        {{event.player_out_name}}
                                    </label>
                                </li>
                            </ul>
                    </div>
                    <div class="sabc_ftbl_listdtl_timer" v-if="left">
                        <h2>Countdown to Kick Off</h2>
                        <span>{{left.days}}</span>
                        <i>:</i>
                        <span>{{left.hours}}</span>
                        <i>:</i>
                        <span>{{left.minutes}}</span>
                        <i>:</i>
                        <span>{{left.seconds}}</span>
                        <div class="sabc_timer_title">
                          <label>Days</label>
                          <i>:</i>
                          <label>Hours</label>
                          <i>:</i>
                          <label>Mins</label>
                          <i>:</i>
                          <label>Secs</label>
                        </div>
                    </div>
                    <div class="ftb_details_header_scoreticker" v-if="match.status != 'NSY'">
                        <!--TODO Slider below is old section-->
                        <div class="match_center_slider_wrap">
                            <div :style="{'width': (match.minutes/0.90)+'%'}" class="match_slider_fill"
                                v-if="match.minutes < 90">
                            </div>
                            <div :style="{'width': 100+'%'}"
                                class="match_slider_fill "
                                v-if="match.minutes >= 90 || match.minutes == 'Finished' || match.minutes == 'Finished AP' || match.minutes == 'Finished AET' || match.minutes == '90+'">
                                <!-- v-if="minutes >= 90 || minutes == 'Finished' || minutes == 'Finished AP' || minutes == 'Finished AET' || minutes == '90+'" :style="{'width': 100+'%'}" -->
                            </div>
                            <div :style="{'width': (45/0.90)+'%'}" class="match_slider_fill "
                                v-if="match.minutes == 'HT'">
                            </div>
                            <div class="match_center_fill_icon_wrap" v-if="match.competitors.length">
                                <span class="sabc_matchdtl_head_status">
                                  KO
                                </span>
                                <span class="sabc_matchdtl_head_status">
                                  HT
                                </span>
                                <span class="sabc_matchdtl_head_status">
                                  FT
                                </span>
                                <span :style="{'left': editedEvent[0].minutes+'%'}" class="match_center_content_span_img home_card"
                                      v-for="editedEvent in editedEvents.home" :key="editedEvent"><!-- v-for="let goal of home_cards"-->
                                    <template v-for="event in editedEvent">
                                      <img v-if="!event.player_in_name" :key="event.minutes" :class="`home_ticker_${event.type}`" :title="(event.minutes) +`'`+ (event.player_name)" :alt="(event.type)+ (event.minutes)+`'`   +(event.player_name)"
                                          :src="require('@/assets/images/'+event.type+'.webp')">
                                    </template>                                    
                                    <strong>{{editedEvent[0].minutes}}'</strong>
                                </span>
                                <span :style="{'left': editedEvent[0].minutes+'%'}" class="match_center_content_span_img away_card"
                                      v-for="editedEvent in editedEvents.away" :key="editedEvent">
                                  <strong>{{editedEvent[0].minutes}}'</strong>
                                  <template v-for="event in editedEvent">
                                    <img v-if="!event.player_in_name" :key="event.minutes" :class="`away_ticker_${event.type}`" :title="(event.minutes) +`'`+ (event.player_name)"  :alt="(event.type)+ (event.minutes)+`'`   +(event.player_name)"
                                         :src="require('@/assets/images/'+event.type+'.webp')">
                                  </template>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ftb_details_tabs" v-if="tabs">
            <a @click="scrollLeftRight('left')" class="prev_btn_dtl_tabs" aria-label="previous arrow" href="javascript:;">
                <img alt="previous arrow" v-if="currentTab > 1" :src="require('@/assets/images/slider_prev_arrow.webp')" width="12" height="21">
            </a>
            <ul ref="menu">
                <li v-if="tabs.overviewTab" ref="Overview" :class="{ selectedTab: $route.name == 'match_detail.overview'}">
                    <router-link
                                :to="{ name: 'match_detail.overview'}">{{ $t("football.Overview")
                        }}
                    </router-link>
                </li>
                <li v-if="tabs.tableTab" ref="Table" :class="{ selectedTab: $route.name =='match_detail.table'}">
                    <router-link
                                :to="{ name: 'match_detail.table'}">{{ $t("football.Table") }}
                    </router-link>
                </li>
                <li v-if="tabs.lineupTab" ref="Line-Ups" :class="{ selectedTab: $route.name =='match_detail.lineup'}">
                    <router-link
                                :to="{ name: 'match_detail.lineup'}">{{ $t("football.Line-Ups") }}
                    </router-link>
                </li>
                <li v-if="tabs.commTab" ref="Commentary" :class="{ selectedTab: (this.$route.path.match(/commentary/))? true : false}">
                    <router-link
                                :to="{ name: 'football_match_detail_commentary'}">{{
                        $t("football.Commentary") }}
                    </router-link>
                </li>
                <li v-if="tabs.statsTab" ref="Stats" :class="{ selectedTab: (this.$route.path.match(/stats/))? true : false}">
                    <router-link
                                :to="{ name: 'football_match_detail_stats'}">{{
                        $t("football.Stats") }}
                    </router-link>
                </li>
                <li v-if="tabs.historyTab" ref="History" :class="{ selectedTab: (this.$route.path.match(/history/))? true : false}">
                    <router-link :to ="{ name: 'football_match_detail_teamhistory'}"  >{{ $t("football.History") }}</router-link>
                </li>
            </ul>
            <a @click="scrollLeftRight('right')" class="next_btn_dtl_tabs" aria-label="next arrow" href="javascript:;">
                <img alt="next arrow" v-if="currentTab < totalTabs" :src="require('@/assets/images/slider_next_arrow.webp')" width="12" height="21">
            </a>
        </div>
      </div>
      <router-view/>
    </div>
</template>


<script>
    import moment from 'moment';
    export default {
        name: "MatchDetail",
        data() {
            return {
                match: null,
                match_started:false,
                tabs: null,
                totalTabs: 1,
                currentTab: 1,
                isTabChanged: false,
                left: null,
                scrollAmount: 0,
                remainingTimeInterval: '',
                editedEvents: null,
                polling: null,
                pathArray: {
                  'Overview': 'overview',
                  'Table': 'table',
                  'Commentary': 'commentary',
                  'Table': 'table',
                  'Line-Ups': 'lineup',
                  'Stats': 'stats',
                  'History': 'teamhistory',
                },

            }
        },
        async mounted() {
            await this.getDetail();
            this.pollData();
            var _this = this;
            this.$root.$on('FBMatchUpdated', data => {
                if (_this.$route.params.matchId == data.match_id) {
                    _this.getDetail()
                }
            });
            this.$root.$on('FBMatchesUpdated', data => {
                if (data.match_ids.includes(_this.$route.params.matchId)) {
                    _this.getDetail()
                }
            });
            // setTimeout(() => {
                this.remainingTimeInterval = setInterval(() => {
                    this.remainingTime();
                }, 1000);
            // }, 1000);

        },
        updated(){
          if(!this.isTabChanged){
            this.scrollLeftRight('');
          }
        },
        beforeDestroy() {
            this.$root.$off('FBMatchUpdated');
            clearInterval(this.remainingTimeInterval);
            this.$root.$off('FBMatchesUpdated')
        },
        serverPrefetch() {
            return this.getDetail();

        },
        jsonld() {

            let events = [];
            if(this.match){
                let competitor1 = this.match.competitors[0].name;
                let competitor2 = this.match.competitors[1].name;
                let tournamentName = this.match.tournament.name;
                let matchDate = moment(this.match.date).format('YYYY-MM-DD');

                events.push(
                    {
                        "@type": "SportsEvent",
                        "name": competitor1 + " VS " + competitor2,
                        "description": tournamentName + " - " + competitor1 + " VS " + competitor2 + " - " + this.match.round,
                        "url": this.$siteURL + "live/football/match-detail/" + this.$slugify(tournamentName +" "+ competitor1 + " VS " + competitor2) +'/'+ matchDate +'/'+ this.match.id +'/overview',
                        "eventStatus": "https://schema.org/EventScheduled",
                        "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
                        "startDate": moment(this.match.date).format('YYYY-MM-DD HH:MM'),
                        "endDate": matchDate,
                        "image": this.$siteURL + 'public/images/logo.png',
                        "organizer": {
                            "@type": "Organization",
                            "name": tournamentName,
                            "url": this.$siteURL + "live/football/league-detail/" + this.$slugify(tournamentName) +'/'+ this.match.tournament.id +'/'+ matchDate,
                        },
                        "offers": {
                            "@type": "Offer",
                            "name": competitor1 + " VS " + competitor2,
                            "url": this.$siteURL + "live/football/match-detail/" + this.$slugify(tournamentName +" "+ competitor1 + " VS " + competitor2) +'/'+ matchDate +'/'+ this.match.id +'/overview',
                            "priceCurrency": "EUR",
                            "validFrom": matchDate,
                            "availability": "https://schema.org/InStock",
                            "price": "0"
                        },
                        "performer": {
                            "@type": "Thing",
                            "name": competitor1 + " VS " + competitor2,
                        },
                        "competitor": [
                            {
                                "@type": "SportsTeam",
                                "name": competitor1,
                                "image": this.$siteURL + 'public/images/logo.png',
                            },
                            {
                                "@type": "SportsTeam",
                                "name": competitor2,
                                "image": this.$siteURL + 'public/images/logo.png',
                            }
                        ],
                        "location": {
                            "@type": "Place",
                            "name": tournamentName,
                            "address": {
                                "@type": "PostalAddress",
                                "name": '',
                            }
                        }
                    }
                )
    
                return {
                    "@context": "https://schema.org",
                    "@graph": events
                };
            }
        },
        methods: {
            //1695319  this.$route.params.matchId
            pollData() {
                this.polling = setInterval(() => {
                    if (this.match) {
                    const minutes = this.minutesCalculate(
                        this.match.status_update_name,
                        this.match.status_update_time,
                        this.match.interrupted_minutes,
                    );
                    if (minutes) {
                        this.match.minutesCal = minutes;
                    }
                    }
                }, 60000);
            },
            async getDetail() {
                await this.$axios
                    .get(this.$MATCH_FOOTBALL_DETAIL_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        this.match = response.data.match;
                        let minutes = this.minutesCalculate(
                            this.match.status_update_name,
                            this.match.status_update_time,
                            this.match.interrupted_minutes,
                        );
                        if (minutes) {
                            this.match.minutesCal = minutes;
                        }
                        let eventsArray = {
                          home: [],
                          away: []
                        };
                        this.match.competitors.forEach(function (comp) {
                          let eventCounter = -1;
                          let eventMinutes = '';
                          let compType = comp.isHome == true ? 'home' : 'away';
                          //remove substitutions from timeline and ticker (below score in header),asked by patrick
                          // comp.events = comp.events.filter(event => {
                          //   return !event.player_in_name;
                          // })
                          comp.events.forEach(event => {
                            if(eventMinutes != event.minutes){
                              eventCounter++;
                              eventMinutes = event.minutes;
                            }
                            if(eventsArray[compType][eventCounter] && eventsArray[compType][eventCounter].length > 0){
                              eventsArray[compType][eventCounter].push(event);
                            } else {
                              eventsArray[compType][eventCounter] = [];
                              eventsArray[compType][eventCounter][0] = event;
                            }
                          });

                        });
                        this.editedEvents = eventsArray;

                        if(this.match.status=='NSY' && !this.match_started){
                            this.remainingTime();
                        }
                        // Trigger mutation handler below
                        this.$store.commit({
                            type: 'setFbMatchDetailResponse',
                            data: this.match,
                        });

                    })
                    .catch(error => console.log(error))

                return this.$axios
                    .get(this.$FB_MATCH_DETAIL_TABS_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        this.tabs = response.data.result;
                        this.totalTabs = Object.values(this.tabs).filter(tab => tab != 0).length;
                    })
                    .catch(error => console.log(error));
            },
            minutesCalculate(statusName, statusUpdatedateTime, interruptedMinutes) {
                if (statusUpdatedateTime && statusUpdatedateTime !== '' && statusName && statusName !== '') {
                    let currentTime = moment(new Date());
                    let statusTime = moment(statusUpdatedateTime);
                    let diff = currentTime.diff(statusTime, 'minutes');

                    if (diff < 0) {
                        return false;
                    }
                    let totalCalMinutes = diff + parseInt(interruptedMinutes || 0, 10);//Math.ceil(diff / 1000 / 60) + parseInt(interruptedMinutes);
                    
                    if (statusName === '1st Half' || statusName === '2nd Half' || statusName === 'ET 1st Half' || statusName === 'ET 2nd Half' || statusName == 'Penalty') {
                        if (statusName === '1st Half') {
                            if (totalCalMinutes > 45) {
                                return 45 + '+';
                            } else {
                                return totalCalMinutes + "'";
                            }
                        }
                        if (statusName === '2nd Half') {
                            if (totalCalMinutes + 46 > 90) {
                                return 90 + '+'; // +46
                            } else {
                                return totalCalMinutes + 46 + "'"; // +46
                            }
                        }
                        if (statusName === 'ET 1st Half') {
                            if (totalCalMinutes + 91 > 105) {
                                return 105 + '+';
                            } else {
                                return totalCalMinutes + 91 + "'"; // +91
                            }
                        }
                        if (statusName === 'ET 2nd Half') {
                            if (totalCalMinutes + 106 > 120) {
                                return 106 + '+';
                            } else {
                                return totalCalMinutes + 106 + "'"; // +106
                            }
                        }
                        if (statusName == 'Penalty') {
                            return 'Pen';
                        }
                    } else {
                        return '';
                    }
                }
            },
            calculateFinalScore(ft, ht) {
                var ft_score = ft.split('-');
                var ht_score = ht.split('-');
                return ((ft_score[0].trim() - ht_score[0].trim()) + ' - ' + (ft_score[1].trim() - ht_score[1].trim()));
            },
            scrollLeftRight(dir = '') {
              let currentElem = document.querySelector(".selectedTab");
              if(currentElem){
                let nextElem ;
                let menu = this.$refs.menu;
                if(dir == 'right' && this.currentTab < this.totalTabs){
                  nextElem = currentElem.nextElementSibling;
                  this.currentTab++;
                } else if(dir == 'left' && this.currentTab > 1 && this.currentTab <= this.totalTabs) {
                  nextElem = currentElem.previousElementSibling;
                  this.currentTab--;
                } else if(!dir){
                  //this condition run on page load only
                  if(currentElem){
                    // console.log('updated');
                    //trim used to fix ios safari issue
                    let currentElemText = currentElem.innerText.trim();
                    //this condition runs for all pages except Overview because overview is always the 1st page
                    if(currentElemText != 'Overview') {
                      let currentElemScroll = currentElem.getBoundingClientRect().right - currentElem.getBoundingClientRect().left;
                      //get tab index to calculate the scrollAmount to scroll to  selected li
                      let tabIndex = Object.keys(this.$refs).findIndex(tab => tab == currentElemText);
                      this.currentTab = 1+tabIndex;
                      this.scrollAmount = currentElemScroll * (this.currentTab-1);
                      menu.scrollTo({left: this.scrollAmount,  behavior: 'smooth'})
                      // currentElem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
                      this.isTabChanged = true;
                    }
                  }
                }
                if(nextElem){
                  let tempScrollAmount = menu.getBoundingClientRect().right - menu.getBoundingClientRect().left;
                  this.scrollAmount = tempScrollAmount * (this.currentTab-1);
                  //scroll to next li
                  menu.scrollTo({left: this.scrollAmount,  behavior: 'smooth'})
                  //add and remove class
                  currentElem.classList.remove("selectedTab");
                  nextElem.classList.add("selectedTab");

                  //trim used to fix ios safari issue
                  let nextElemName = nextElem.innerText.trim();

                  setTimeout(() => {
                    this.$router.push({ path: this.pathArray[nextElemName]});
                  }, 500);
                }
              }
            },
            remainingTime() {
                var a = moment();
                var b = moment(this.match.date);
                var diff = b.diff(a, 'seconds');
                if(diff < 0){
                  this.match_started = true;
                  clearInterval(this.remainingTimeInterval);
                  return '';
                }
                diff = Math.abs(diff);
                var day = 86400;
                var hour = 3600;
                var minute = 60;

                var totalseconds = diff;
                if (this.left == null) {
                    this.left = {days:'',hours:'',minutes:'',seconds:''}
                }

                this.left.days = Math.floor(totalseconds / day);
                this.left.days = this.left.days > 9 ? this.left.days : '0'+this.left.days;
                this.left.hours = Math.floor((totalseconds - this.left.days * day)/hour);
                this.left.hours = this.left.hours > 9 ? this.left.hours : '0'+this.left.hours;
                this.left.minutes = Math.floor((totalseconds - this.left.days * day - this.left.hours * hour)/minute);
                this.left.minutes = this.left.minutes > 9 ? this.left.minutes : '0'+this.left.minutes;
                this.left.seconds = totalseconds - this.left.days * day - this.left.hours * hour - this.left.minutes * minute;
                this.left.seconds = this.left.seconds > 9 ? this.left.seconds : '0'+this.left.seconds;

                return this.left;
            },
            winLose(match, team) {
                var homeScore = '';
                var awayScore = '';
                var teamStatus = '';
                match.competitors.forEach((item)=>{
                    if(item.id == team) {
                        if(item.isHome) {
                            teamStatus = 'home';
                        } else {
                            teamStatus = 'away';
                        }
                    }
                });

                if(match.extraTime != '') {
                    homeScore = match.extraTime.split('-')[0];
                    awayScore = match.extraTime.split('-')[1];
                    if(match.penaltyScore != '') {
                        homeScore = match.penaltyScore.split('-')[0];
                        awayScore = match.penaltyScore.split('-')[1];
                    }
                } else {
                    homeScore = match.fullTime.split('-')[0];
                    awayScore = match.fullTime.split('-')[1];
                }
                if(teamStatus == 'home' && homeScore>awayScore) {
                    return 'W';
                } else if (teamStatus == 'home' && homeScore<awayScore) {
                    return 'L';
                } else if (teamStatus == 'away' && homeScore>awayScore) {
                    return 'L';
                } else if (teamStatus == 'away' && homeScore<awayScore) {
                    return 'W';
                } else {
                    return 'D';
                }
            }
        }
    }
</script>
